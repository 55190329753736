/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #161616;
$primary: #18191d;
$secondary: #666;
$ternary: #f3f3f3;
$border: #eaeaea;
$gray: #f2f3f7;
$accent: #16878d;
$secondary_grey:#CFCFCF;